import React from 'react';
import { List, Card, CardHeader, CardBody, Flex, Provider, teamsTheme, Button, teamsDarkTheme, Text } from '@fluentui/react-northstar'
import './LoginPage.css';
import ReactDOM from 'react-dom';

class LoginPage extends React.Component
{
    items = [
        {
            key: 'AAD',
            content:
                (
                    <Button className="loginButton"
                        fluid
                        icon=
                        {
                            <svg xmlns="http://www.w3.org/2000/svg" role="img" height="20px" width="20px" viewBox="0 0 24 24">
                                <path d="m11.879 11.879h-11.879v-11.879h11.879z" fill="#f1511b"/>
                                <path d="m24.996 11.879h-11.88v-11.879h11.879v11.879z" fill="#80cc28"/>
                                <path d="m11.879 25.00h-11.879v-11.879h11.879z" fill="#00adef"/>
                                <path d="m24.996 25.00h-11.88v-11.879h11.879v11.879z" fill="#fbbc09"/>
                            </svg>
                        }
                        onClick={() => { window.location.href = `/.auth/login/aad?post_login_redirect_url=${readURLCookie('RedirectURL')}` }}
                        content="Sign in with the Microsoft"
                    />
                ),
        },
        /*{
            key: 'Facebook',
            content:
                (
                    <Button className="loginButton"
                        fluid
                        icon=
                        {
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 12.067C0 18.0335 4.33333 22.9944 10 24V15.3333H7V12H10V9.33332C10 6.33332 11.9333 4.66666 14.6667 4.66666C15.5333 4.66666 16.4667 4.79999 17.3333 4.93332V7.99999H15.8C14.3333 7.99999 14 8.73332 14 9.66666V12H17.2L16.6667 15.3333H14V24C19.6667 22.9944 24 18.0335 24 12.067C24 5.43017 18.6 0 12 0C5.4 0 0 5.43017 0 12.067Z" fill="black" />
                            </svg>
                        }
                        onClick={() => { window.location.href = "/.auth/login/facebook?post_login_redirect_url=/" }}
                        content="Log in with Facebook"
                    />
                ),
        },*/
        {
            key: 'Google',
            content:
                (
                    <Button className="loginButton"
                        fluid
                        icon=
                        {
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" role="img" viewBox="0 0 24 24">
                                <title>Google icon</title>
                                <g xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                                    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                                    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                                    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                                </g>
                            </svg>
                        }
                        onClick={() => { window.location.href = `/.auth/login/google?access_type=offline&post_login_redirect_url=${readURLCookie('RedirectURL')}` }}
                        content="Sign in with Google"
                    />
                ),
        },
        /*{
            key: 'TWitter',
            content:
                (
                    <Button className="loginButton"
                        fluid
                        icon=
                        {
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" viewBox="0 0 1024 1024">
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z" />
                            </svg>
                        }
                        onClick={() => { window.location.href = "/.auth/login/twitter?post_login_redirect_url=/" }}
                        content="Log in with Twitter"
                    />
                ),
        }*/
    ]

    render()
    {        
        return (
            <div>                
                <Provider style={ { height: "40px" } } theme={ teamsDarkTheme }>
                    <Text size="larger" style={ { padding: "5px 10px 5px 10px" } } content="Ticket D365" align="center" />
                </Provider>
                <Provider theme={teamsTheme}>
                    <Flex className="loginPage">                    
                        <Card className="loginCard" selected elevated centered size="largest">
                            <CardHeader>                            
                                {/*<h2> Log in with:</h2>*/}
                                {                                    
                                    <>
                                    <img src="/logoName.png" width="200px" />                                                                            
                                    {/*<Text content={readURLCookie('RedirectURL')} />*/}
                                    </>
                                }  
                            </CardHeader>
                            <CardBody>
                                <List items={this.items} />
                            </CardBody>
                        </Card>
                    </Flex>
                </Provider>
            </div>
        )
    }
}

export function readURLCookie(name: string): string
{
    const CookieArray = decodeURIComponent(document.cookie).split(";")
    const cookie = CookieArray.find(cookie => cookie.indexOf(name + "=") >= 0)
    if (cookie)
    {
        const cookieContent = cookie.trim().substring(name.length + 1)    
        console.log('Url cookie content', cookieContent);
        return cookieContent;
    }
    return ""
}

ReactDOM.render(<LoginPage />, document.getElementById("root"));